<template>
  <FormModal title="Déménagement de" show-back-btn>
    <template #body>
      <MovingFromFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import MovingFromFormStep from '~/components/form/steps/movingFrom/MovingFromFormStep'

export default {
  components: {
    FormModal,
    MovingFromFormStep,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Déménagement depuis',
      headDescription: "Veuillez indiquer l'endroit d'où vous allez déménager.",
      path: '/quotes-requests/demenagement-de',
      progressValue: 15,
      checkoutStep: 2,
    }
  },
}
</script>
