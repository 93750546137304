<template>
  <div>
    <AddressFormGroup v-on="$listeners" />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import AddressFormGroup from 'chimera/moving/components/form/parts/address/AddressFormGroup'
import { movingConsumerBE } from 'chimera/moving/service'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'

export default {
  name: 'MovingFromAddressFormPart',

  components: {
    AddressFormGroup,
    ServiceField,
  },

  /**
   */
  mounted() {
    // Set default service to `moving-consumer`
    this.$refs.service.setValue(movingConsumerBE.id)
  },
}
</script>
