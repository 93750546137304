<template>
  <div>
    <AddressPropertyTypeRadioField
      :choices="choices"
      v-on="$listeners"
      @result="onPropertyTypeResult($event.value[0])"
    />

    <div v-if="showFloorLevelInput" class="form-row mt-3">
      <AddressFloorLevelField
        :label="$t('field.address-floor-level.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import AddressPropertyTypeRadioField from 'chimera/all/components/form/fields/addressPropertyType/AddressPropertyTypeRadioField'
import AddressFloorLevelField, {
  field as addressFloorLevelField,
} from 'chimera/all/components/form/fields/addressFloorLevel/AddressFloorLevelField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'MovingFromPropertyTypeFormPart',

  components: {
    AddressFloorLevelField,
    AddressPropertyTypeRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      showFloorLevelInput: false,
      choices: [
        new Selectable(this.$t('house'), this.$t('house'), this.$t('house')),
        new Selectable(
          this.$t('apartment'),
          this.$t('apartment'),
          this.$t('apartment'),
        ),
      ],
    }
  },

  methods: {
    /**
     * @param {object} propertyType
     * @param {string} propertyType.id
     */
    onPropertyTypeResult({ id }) {
      this.showFloorLevelInput = this.$i18n.t('apartment') === id

      if (!this.showFloorLevelInput) {
        this.$store.dispatch('lead/rm', addressFloorLevelField)
      }
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "house": "Huis",
    "apartment": "Appartement"
  },
  "nl-BE": {
    "house": "Huis",
    "apartment": "Appartement"
  },
  "es-ES": {
    "house": "Casa",
    "apartment": "Apartamento"
  },
  "de-DE": {
    "house": "Haus",
    "apartment": "Wohnung"
  },
  "fr-BE": {
    "house": "Maison",
    "apartment": "Appartement"
  },
  "fr-FR": {
    "house": "Maison",
    "apartment": "Appartement"
  }
}
</i18n>
